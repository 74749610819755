/** @jsx jsx */
import { jsx } from 'theme-ui'
import { graphql } from 'gatsby'
import { RichText, linkResolver } from 'prismic-reactjs'
import { Box, Flex } from 'rebass'
import { uniqueId } from 'lodash'
import { MdAdd } from "react-icons/md";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import { Container, Layout, Section } from '@giraldomac/gatsby-theme-mmdbase/src/components/layout'
import { SEO } from '@giraldomac/gatsby-theme-mmdbase/src/components/elements'
import { PubHero } from '../../../components/elements'


const Index = ({ data }) => {

  const page = data.prismic.faqs_customer_service

  return (
    <Layout>
      <SEO meta_title={page.meta_title} meta_description={page.meta_description} />
      <PubHero title={`${RichText.asText(page.title)} FAQs`} />
      <Section >
        <Container>
          <Flex flexWrap="wrap" justifyContent="center">
            <Box width={['full', 4 / 5, 3 / 4]} sx={{
              backgroundColor: 'wrapperbox',
              padding: [4, 5],
            }}>
              {RichText.render(page.content)}

              <Accordion allowMultipleExpanded allowZeroExpanded>
                {page.body.map(({ fields }) => (
                  <Box width={["full"]} key={`${uniqueId('faq_group_')}`}>
                    {
                      fields.map(({ question, answer }) => (
                        <AccordionItem key={`${uniqueId('faq_item_')}`}>
                          <Box width={['full']} >
                            <AccordionItemHeading>
                              <AccordionItemButton sx={{
                                borderBottom: '1px solid',
                                borderColor: 'gray.2',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                width: '100%'
                              }}>
                                <Box><MdAdd sx={{ fill: 'primary', mr: 3 }} /></Box>
                                <Box sx={{ justifySelf: 'flex-start' }}><h3 as="h2" sx={{
                                  marginBottom: 0, py: [2, 3]
                                }}>{RichText.asText(question)}</h3></Box>
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel sx={{ py: 3, px: [3, 4] }}>
                              {RichText.render(answer, linkResolver)}
                            </AccordionItemPanel>
                          </Box>
                        </AccordionItem>
                      ))
                    }
                  </Box>
                ))}
              </Accordion>

            </Box>

          </Flex>
        </Container>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  {
    prismic {
    faqs_customer_service(lang: "en-us", uid: "customer-service") {
      body {
        ...on PRISMIC_Faqs_customer_serviceBodyFaqs {
          type
          label
          fields {
            answer
            question
          }
      }
    }
      content
      meta_description
      meta_title
      title
      _linkType
    }
  }
}
`

export default Index
